import React from "react";
import "./styles.css";

import logo from "../../assets/logo_topo.png";
import icon from "../../assets/iconwhats2.png";

const Menu = () => {
  return (
    <nav id="menu">
      <section className="container">
        <figure className="left">
          <img src={logo} alt="Logo" />
        </figure>

        <article className="text1">
          <b>Conheça nosso trabalho,</b> vamos elaborar juntos um projeto de
          sucesso!
        </article>

        <article className="text2">
          Alguma dúvida? <b>Fale conosco:</b>{" "}
        </article>

        <article className="link">
          <figure>
            <img src={icon} alt="Ícone do Whatsapp" />
          </figure>

          <a
            href="http://wa.me/+553196881031?text=Olá,%20gostaria%20de%20saber%20mais%20sobre%20a%20abcfarma%20moveis"
            target="_blank"
            rel="noreferrer"
          >
            (31) 9688-1031
          </a>
        </article>

        {/* <article className="link">
          <figure>
            <img src={icon} alt="Ícone do Whatsapp" />
          </figure>

          <a
            href="http://wa.me/+5511993631129?text=Olá,%20gostaria%20de%20saber%20mais%20sobre%20a%20abcfarma%20moveis"
            target="_blank"
            rel="noreferrer"
          >
            (11) 99363-1129
          </a>
        </article> */}

        <article className="mobile">
          <article className="text">
            Alguma dúvida? <b>Fale conosco:</b>{" "}
          </article>

          <div className="bottom">
            <article className="link">
              <figure>
                <img src={icon} alt="Ícone do Whatsapp" />
              </figure>

              <a
                href="http://wa.me/+5521999472482?text=Olá,%20gostaria%20de%20saber%20mais%20sobre%20a%20abcfarma%20moveis"
                target="_blank"
                rel="noreferrer"
              >
                (21) 99947-2482
              </a>
            </article>

            {/* <article className="link">
              <figure>
                <img src={icon} alt="Ícone do Whatsapp" />
              </figure>

              <a
                href="http://wa.me/+55119936311293?text=Olá,%20gostaria%20de%20saber%20mais%20sobre%20a%20abcfarma%20moveis"
                target="_blank"
                rel="noreferrer"
              >
                (11) 99363-1129
              </a>
            </article> */}
          </div>
        </article>
      </section>
    </nav>
  );
};

export default Menu;
