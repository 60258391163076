import "./styles.css";
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import header2_1 from "../../assets/header2_1.png";
import header2_2 from "../../assets/header2_2.png";
import header2_3 from "../../assets/header2_3.png";
import header2_4 from "../../assets/header2_4.png";
import header2_5 from "../../assets/header2_5.png";
import header2_6 from "../../assets/header2_6.png";
import header2_7 from "../../assets/header2_7.png";

import CtaWpp from "../../components/CTAWpp";

SwiperCore.use([Navigation, Pagination, Autoplay]);

const data = [
  {
    id: 1,
    image: header2_1,
  },
  {
    id: 2,
    image: header2_2,
  },
  {
    id: 3,
    image: header2_3,
  },
  {
    id: 4,
    image: header2_4,
  },
  {
    id: 5,
    image: header2_5,
  },
  {
    id: 6,
    image: header2_6,
  },
  {
    id: 7,
    image: header2_7,
  },
];

const Header = () => {
  return (
    <header id="header">
      <section className="header1">
        <article className="container">
          <CtaWpp />
        </article>
      </section>

      <section className="mobile">
        <div className="container"><b>Conheça nosso trabalho,</b> vamos elaborar juntos um projeto de sucesso!</div>
      </section>

      <section className="header2">
        <article className="container">
          <h1 className="title">Produtos abcfarma móveis</h1>

          <h2 className="subtitle">
            Fabricação de móveis para lojas, farmácias, papelaria, perfumaria,
            pet shops, móveis planejados residenciais e comércios em geral.
          </h2>

          <p className="text">
            Veja alguns projetos e produtos que lhe oferecemos!
          </p>

          <Swiper
            className="carousel"
            spaceBetween={5}
            slidesPerView={
              /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
              )
                ? 1
                : 3
            }
            navigation
            loop
            pagination={{ clickable: true }}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
          >
            {data.map(({ id, image }) => {
              return (
                <SwiperSlide key={id}>
                  <figure>
                    <img
                      src={image}
                      alt={`Exemplo ${id} de Foto de Farmácia montada`}
                    />
                  </figure>
                </SwiperSlide>
              );
            })}
          </Swiper>

          <p className="text">
            Renove o visual do seu estabelecimento comercial, fale conosco agora
            mesmo!
          </p>

          <CtaWpp />
        </article>
      </section>
    </header>
  );
};

export default Header;
