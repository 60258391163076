import "./styles.css";
import YouTube from "react-youtube";

import CtaWpp from "../../components/CTAWpp";

import bullet from "../../assets/bullet.png";

const About = () => {
  const opts = {
    height: "350",
    width: "550",
  };

  const optsMobile = {
    height: "300",
    width: "350",
  };

  return (
    <section id="about">
      <article className="about1">
        <div className="container">
          <div className="left">
            <h2 className="title">Por que escolher a ABCFARMA?</h2>

            <p className="text">
              A abcfarma móveis tem produtos diferenciados, qualidade, projetos
              exclusivos ou padronizados.{" "}
              <b>Por um valor justo, instalaremos sua loja/farmácia.</b>
            </p>

            <CtaWpp />
          </div>

          <ul className="right">
            <li>
              <figure>
                <img src={bullet} alt="Bullet Point" />
              </figure>

              <p>Qualidade</p>
            </li>

            <li>
              <figure>
                <img src={bullet} alt="Bullet Point" />
              </figure>

              <p>Resistência</p>
            </li>

            <li>
              <figure>
                <img src={bullet} alt="Bullet Point" />
              </figure>

              <p>Durabilidade</p>
            </li>

            <li>
              <figure>
                <img src={bullet} alt="Bullet Point" />
              </figure>

              <p>Design sofisticado</p>
            </li>

            <li>
              <figure>
                <img src={bullet} alt="Bullet Point" />
              </figure>

              <p>Projetos exclusivos</p>
            </li>
          </ul>

          <div className="mobile">
            <CtaWpp />
          </div>
        </div>
      </article>

      <article className="about2">
        <div className="container">
          <div className="left">
            <h2 className="title">Entenda nosso serviço</h2>

            <p className="text">
              A abcfarma móveis oferece a você, proprietário de farmácia ou
              perfumaria, o que há de mais moderno em mobiliário, seja para uma
              reforma completa ou parcial. Nos convide para uma parceria de
              sucesso e desenvolvimento daquilo que será estudado por
              profissionais com muita experiência no setor, para atualizar seu
              layout e aumentar suas vendas!
            </p>

            <CtaWpp />
          </div>

          <div className="right">
            <h2 className="title">Entenda nosso serviço</h2>

            <YouTube
              videoId="16X3Gjsx_Vc"
              opts={
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                  navigator.userAgent
                )
                  ? optsMobile
                  : opts
              }
            />
          </div>
        </div>
      </article>

      <article className="about3">
        <div className="container">
          <div className="left">
            <h2 className="title">
              A <span>abcfarma móveis</span> oferece a você um layout moderno
            </h2>

            <YouTube
              videoId="rxV-v2dJfAg"
              opts={
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                  navigator.userAgent
                )
                  ? optsMobile
                  : opts
              }
            />
          </div>

          <div className="right">
            <h2 className="title">
              A <span>abcfarma móveis</span> oferece a você um layout moderno
            </h2>

            <p className="text">
              Desenvolvemos um layout timizado, que trará mais conforto e
              satisfação para seus clientes, aumentando assim seu ticket médio e
              aumentando suas vendas.
            </p>

            <CtaWpp />
          </div>
        </div>
      </article>

      <article className="about4">
        <div className="container">
          <div className="left">
            <h2 className="title">Consultoria ABCFARMA MÓVEIS</h2>

            <p className="text">
              Com a consultoria ABCFARMA MÓVEIS,{" "}
              <b>orientamos seu projeto do início à conclusão</b>, começando com
              as medicações, planta baixa, projeto 3d, orçamento, aprovação do
              cliente e execução da obra. Sempre nos empenhando e buscando a
              satisfação de nossos clientes.
            </p>

            <CtaWpp />
          </div>

          <div className="right">
            <h2 className="title">Consultoria ABCFARMA MÓVEIS</h2>

            <YouTube
              videoId="qB_o1RGKsk4"
              opts={
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                  navigator.userAgent
                )
                  ? optsMobile
                  : opts
              }
            />
          </div>
        </div>
      </article>
    </section>
  );
};

export default About;
