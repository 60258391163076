import { useEffect, useState } from "react";
import "./styles.css";

import icon from "../../assets/iconwhats.png";

const CtaWpp = (props) => {
  const [text, setText] = useState("SOLICITAR ORÇAMENTO");

  useEffect(() => {
    if (props.text) setText(props.text);
  }, [props.text]);

  return (
    <button id="ctaWpp">
      <a
        href="http://wa.me/+553196881031?text=Olá,%20gostaria%20de%20saber%20mais%20sobre%20a%20abcfarma%20moveis"
        target="_blank"
        rel="noreferrer"
      >
        <figure>
          <img src={icon} alt="Ícone do Whatsapp" />
        </figure>
        <p>{text}</p>
      </a>
    </button>
  );
};

export default CtaWpp;
