import "./styles.css";

import stars from "../../assets/stars.png";

import CtaWpp from "../../components/CTAWpp";

const Content = () => {
  return (
    <section id="content">
      <article className="content1">
        <div className="container">
          <h2 className="title">Qualidade garantida!</h2>

          <p className="text2">
            Na hora de montar uma loja é importante levar em conta quais os
            móveis que irão compor o ambiente. Aí a importância de investir em
            móveis que alinhem <b>qualidade</b> e muito bom gosto. Aqui na{" "}
            <b>ABCFARMA MÓVEIS</b> você vai encontrar uma linha completa de
            móveis para compor a sua loja física, são balcões, gôndolas,
            prateleiras para medicamentos, perfumaria e expositores com
            ganchinhos em réguas de ferros ou canaletados, armários para
            controlados, displays. Temos também projetos e mobiliário completo
            ou apenas acessórios para lojas e comércio em geral. Tudo isso pra
            você fazer a diferença no mercado,e oferecer mais conforto e
            comodidade para seus clientes. Venha para a <b>ABCFARMA MÓVEIS</b>!
          </p>

          <CtaWpp />

          <div className="mobile">
            <div className="gallery">
              <div className="item">
                <figure>
                  <img src={stars} alt="5 estrelas" />
                </figure>

                <p className="text1">100%</p>
                <p className="text2">Qualidade</p>
              </div>

              <div className="item">
                <figure>
                  <img src={stars} alt="5 estrelas" />
                </figure>

                <p className="text1">100%</p>
                <p className="text2">Dedicação</p>
              </div>

              <div className="item">
                <figure>
                  <img src={stars} alt="5 estrelas" />
                </figure>

                <p className="text1">100%</p>
                <p className="text2">Aprovação</p>
              </div>
            </div>

            <CtaWpp
              text={"QUERO REFORMAR MINHA FARMÁCIA COM MÓVEIS INOVADORES"}
            />
          </div>
        </div>
      </article>

      <article className="content2">
        <div className="container">
          <div className="gallery">
            <div className="item">
              <figure>
                <img src={stars} alt="5 estrelas" />
              </figure>

              <p className="text1">100%</p>
              <p className="text2">Qualidade</p>
            </div>

            <div className="item">
              <figure>
                <img src={stars} alt="5 estrelas" />
              </figure>

              <p className="text1">100%</p>
              <p className="text2">Dedicação</p>
            </div>

            <div className="item">
              <figure>
                <img src={stars} alt="5 estrelas" />
              </figure>

              <p className="text1">100%</p>
              <p className="text2">Aprovação</p>
            </div>
          </div>

          <CtaWpp
            text={"QUERO REFORMAR MINHA FARMÁCIA COM MÓVEIS INOVADORES"}
          />
        </div>
      </article>
    </section>
  );
};

export default Content;
